/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

import {
  DisplayText,
  Header,
  LayoutUI,
  Stack,
  TextStyle,
  WithFloatingArrow
} from '../ui-kit';

const NotFound = () => (
  <Layout>
    <div className="Not-found-container">
      <LayoutUI narrow>
        <Stack
          alignment="center"
          distribution="center"
          spacing="loose"
          vertical
        >
          <DisplayText>404 Not Found</DisplayText>
          <Header size="small">
            We couldn't find the page you were looking for.
          </Header>
          <WithFloatingArrow>
            {floatingArrow => (
              <Link to="/">
                <TextStyle color="blue">
                  Go Home
                  {floatingArrow}
                </TextStyle>
              </Link>
            )}
          </WithFloatingArrow>
        </Stack>
      </LayoutUI>
    </div>
  </Layout>
);

export default NotFound;
